import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import Features from '../components/landing/Features';
import FlexiblePricing from '../components/landing/FlexiblePricing';
import FullWidthBanner from '../components/landing/FullWidthBanner';
import Hero from '../components/landing/Hero';
import IconCardsWithText from '../components/landing/IconCardsWithText';
import ImageCarouselWithText from '../components/landing/ImageCarouselWithText';
import '../components/landing/scss/LandingPage.scss';
import { ViewLayout } from '../components/layout';
import Pricing from '../components/landing/Pricing';
import StatisticList from '../components/landing/StatisticList';
import Testimonials from '../components/landing/Testimonials';

class Home extends Component {
  constructor(props) {
    super(props);
    const languageSetting = 'en';
    // Uncomment the following if required for internationalization
    /*
    if (props.data.locales) {
      languageSetting = props.data.locales.edges[0].node.language;
    } else if (props.pageResources.json.data.locales) {
      languageSetting = props.pageResources.json.data.locales.edges[0].node.language;
    }
     */
    this.state = {
      isLoaded: false,
      // Set language as defined in GraphQL query, probably will be deprecated when official
      // internationalization support is introduced in Strapi
      language: languageSetting,
      // Location of page query data differs from develop to build, account for both:
      homePageData:
        props.data.strapiHomePage ||
        props.pageResources.json.data.strapiHomePage,
    };
  }

  /**
   * Get the defined content for each component within the Home Page
   *
   * @param contentBlock
   * @param i
   * @return {JSX.Element}
   */
  getHomePageContent(contentBlock, i) {
    let componentContent;
    switch (contentBlock.strapi_component) {
      case 'page-elements.hero':
        componentContent = (
          <Hero
            heroTitle={contentBlock.HeroTitle}
            heroSubTitle={contentBlock.HeroSubtitle}
            heroButtonURL={contentBlock.HeroButtonURL}
            heroButtonText={contentBlock.HeroButtonText}
            heroBackground={contentBlock.HeroImage.url}
            key={i}
          />
        );
        break;
      case 'page-elements.full-width-banner':
        componentContent = (
          <FullWidthBanner
            desktopImage={contentBlock.desktopImage.url}
            tabletImage={contentBlock.tabletImage.url}
            mobileImage={contentBlock.mobileImage.url}
            key={i}
          />
        );
        break;
      case 'page-elements.icon-cards-with-text':
        componentContent = (
          <IconCardsWithText
            Title={contentBlock.Title}
            Cards={contentBlock.Cards}
            key={i}
          />
        );
        break;
      case 'page-elements.image-carousel-with-text':
        componentContent = (
          <ImageCarouselWithText
            Title={contentBlock.Title}
            subtitle1={contentBlock.subtitle1}
            subtitle2={contentBlock.subtitle2}
            description={contentBlock.description}
            slideImages={contentBlock.slideImages}
            buttonText={contentBlock.buttonText}
            buttonUrl={contentBlock.buttonUrl}
            key={i}
          />
        );
        break;
      case 'page-elements.pricing':
        componentContent = (
          <FlexiblePricing
            Title={contentBlock.Title}
            PricingCards={contentBlock.PricingCards}
            key={i}
          />
        );
        break;
      case 'page-elements.new-pricing':
        componentContent = <Pricing key={i} />;
        break;
      case 'page-elements.features':
        componentContent = (
          <Features FeatureList={contentBlock.FeatureList} key={i} />
        );
        break;
      case 'page-elements.testimonials':
        componentContent = (
          <Testimonials
            Title={contentBlock.Title}
            TestimonialCard={contentBlock.TestimonialCard}
            key={i}
          />
        );
        break;
      case 'page-elements.statistic-list':
        componentContent = (
          <StatisticList
            Title={contentBlock.Title}
            description={contentBlock.description}
            StatisticSquares={contentBlock.StatisticSquares}
            key={i}
          />
        );
        break;
      default:
        componentContent = <div>Unrecognized Component</div>;
        break;
    }
    return componentContent;
  }

  render() {
    const { error, language, homePageData } = this.state;

    if (error) {
      return <div>Error: {error.message}</div>;
    }
    return (
      <>
        <Helmet
          htmlAttributes={{
            lang: language,
          }}
        >
          <meta charSet="utf-8" />
          <title>{homePageData.Title}</title>
        </Helmet>
        <ViewLayout type="landing">
          <div className="landing-page">
            {homePageData.Content.map((pageComponent, i) =>
              this.getHomePageContent(pageComponent, i)
            )}
          </div>
        </ViewLayout>
      </>
    );
  }
}

export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    strapiHomePage {
      Title
      Content {
        strapi_component
        Title
        description
        buttonText
        buttonUrl
        desktopImage {
          url
        }
        tabletImage {
          url
        }
        mobileImage {
          url
        }
        HeroTitle
        HeroSubtitle
        HeroButtonText
        HeroButtonURL
        HeroImage {
          url
        }
        PricingCards {
          body
          bodyCollapsible
          buttonSolid
          buttonText
          buttonUrl
          color
          header
          subheader
          title
        }
        Cards {
          icon {
            height
            url
            size
            name
            width
            alternativeText
          }
          title
          description
        }
        FeatureList {
          feature
        }
        StatisticSquares {
          coloredText
          coloredTextColor
          linearGradient
          subtext
          icon {
            width
            height
            url
            alternativeText
          }
        }
        TestimonialCard {
          name
          quote
          field
          picture {
            width
            height
            alternativeText
            url
          }
        }
        slideImages {
          alternativeText
          caption
          formats
        }
        subtitle1
        subtitle2
      }
    }
  }
`;

export default Home;

// export const query = graphql`
//   query($language: String!) {
//     locales: allLocale(language: {eq: $language}}) {
//       edges {
//         node {
//           ns
//           data
//           language
//         }
//       }
//     }
//   }
// `;
