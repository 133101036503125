import { Link } from 'gatsby';
import React, { Component } from 'react';
import './scss/Hero.scss';

class Hero extends Component {
  constructor(props) {
    super(props);
    this.render = this.render.bind(this);
  }

  render() {
    return (
      <section className="Hero">
        <style
          dangerouslySetInnerHTML={{
            __html: `
          .Hero {
            background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%),
                              url(${this.props.heroBackground});
          }

          @media (min-width: 992px) {
            .Hero {
              background-image: url(${this.props.heroBackground});
            }
          }
        `,
          }}
        />

        <div className="text-container">
          <h1>{this.props.heroTitle}</h1>
          <h2>{this.props.heroSubTitle}</h2>
          {/* eslint-disable-next-line consistent-return */}
          {(() => {
            if (this.props.heroButtonURL && this.props.heroButtonText) {
              return (
                <Link to={this.props.heroButtonURL} className="primary-button">
                  {this.props.heroButtonText}
                </Link>
              );
            }
          })()}
        </div>
      </section>
    );
  }
}
export default Hero;
