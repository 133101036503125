import { Link } from 'gatsby';
import React, { Component } from 'react';
import './scss/FlexiblePricing.scss';
import { Arrow } from '../icons';

class FlexiblePricing extends Component {
  constructor(props) {
    super(props);
    this.render = this.render.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  toggle(event) {
    const el = event.currentTarget;
    if (el) {
      const collapsibleDiv = el.nextElementSibling;
      const arrow = el.childNodes[1];
      if (window.getComputedStyle(collapsibleDiv).maxHeight === '0px') {
        collapsibleDiv.style.maxHeight = `${collapsibleDiv.scrollHeight}px`;
        arrow.style.transform = 'rotate(180deg)';
      } else {
        collapsibleDiv.style.maxHeight = '0px';
        arrow.style.transform = 'rotate(0deg)';
      }
    }
  }

  render() {
    function ColorLuminance(hex, lum) {
      // validate hex string
      // eslint-disable-next-line no-param-reassign
      hex = String(hex).replace(/[^0-9a-f]/gi, '');
      if (hex.length < 6) {
        // eslint-disable-next-line no-param-reassign
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
      }
      // eslint-disable-next-line no-param-reassign
      lum = lum || 0;

      // convert to decimal and change luminosity
      let newHex = '#';
      let c;
      let i;
      for (i = 0; i < 3; i++) {
        c = parseInt(hex.substr(i * 2, 2), 16);
        c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
        newHex += `00${c}`.substr(c.length);
      }

      return newHex;
    }

    return (
      <section className="FlexiblePricing" id="pricing">
        <style
          dangerouslySetInnerHTML={{
            __html:
              `
          .card-grid {
            ${
              this.props.PricingCards &&
                this.props.PricingCards.map(
                  (card, i) => `--card${i}-color: ${card.color};`
                ).join('')
              }${this.props.PricingCards &&
                this.props.PricingCards.map((card, i) => {
                  const darkerColor = ColorLuminance(card.color, -0.1);
                  return `--card${i}-hover-color: ${darkerColor};`;
                }).join('')
              }
          }
        `,
          }}
        />

        <h3>{this.props.Title}</h3>

        <div className="card-grid">
          {this.props.PricingCards &&
            this.props.PricingCards.map((card, i) => (
              <React.Fragment key={i}>
                <div className="card card-title" index={i}>
                  <h4>{card.title}</h4>
                  <div className="card-banner-box">
                    <div className="ribbon">
                      <p>Extended</p>
                      <p>Free Trial</p>
                    </div>
                  </div>
                </div>
                <div className="card card-head" index={i}>
                  <div className="text-container">
                    <h5 className="header">{card.header}</h5>
                    <h6 className="subheader">{card.subheader}</h6>
                  </div>
                  <hr />
                </div>
                <div className="card card-body" index={i}>
                  <div
                    className="body"
                    dangerouslySetInnerHTML={{ __html: card.body }}
                  />
                  <button className="toggler" onClick={this.toggle}>
                    See More Details <Arrow />
                  </button>
                  <div
                    className="body-collapsible"
                    dangerouslySetInnerHTML={{ __html: card.bodyCollapsible }}
                  />
                </div>

                <div className="card card-button" index={i}>
                  {card.buttonUrl && card.buttonText && (
                    <Link
                      to={card.buttonUrl}
                      className={card.buttonSolid ? '' : 'outline'}
                    >
                      {card.buttonText}
                    </Link>
                  )}
                </div>
              </React.Fragment>
            ))}
        </div>
      </section>
    );
  }
}

export default FlexiblePricing;
