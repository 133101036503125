import React, { Component } from 'react';
import './scss/StatisticList.scss';
import Glide from '@glidejs/glide';

class StatisticList extends Component {
  constructor(props) {
    super(props);
    this.render = this.render.bind(this);

    const sliderConfiguration = {
      slidesToShow: 1,
      slidesToScroll: 1,
      gap: 0,
      draggable: true,
      dots: '.glide__bullets',
      type: 'carousel',
      startAt: 1,
    };

    this.slider = new Glide('.glide-square', sliderConfiguration);

    // Update glider dots at the beginning of slide change instead of the end
    this.slider.on('run.before', (s) => {
      const slideCount = this.slider._c.Html.slides.length;
      const sliderContainer = document.querySelector(
        `${this.slider.selector} .glide__bullets`
      );
      let { index } = this.slider;
      sliderContainer.children[index].classList.remove('glide__bullet--active');
      if (s.direction === '>') {
        index = (++index + slideCount) % slideCount; // Handles wrapping
      } else if (s.direction === '<') {
        index = (--index + slideCount) % slideCount; // Handles wrapping
      } else if (s.direction === '=') {
        index = parseInt(s.steps, 10);
      } else {
        console.log(`Unsupported direction "${s.direction}"`);
      }
      sliderContainer.children[index].classList.add('glide__bullet--active');
    });
  }

  componentDidMount() {
    if (this.props.StatisticSquares.length === 4) {
      this.slider.mount();
    }
  }

  componentWillUnmount() {
    this.slider.destroy();
  }

  render() {
    if (this.props.StatisticSquares.length === 4) {
      return (
        <section className="StatisticList">
          <h3>{this.props.Title}</h3>
          <h4>{this.props.description}</h4>

          <div className="squares-container">
            <div className="square-group">
              {this.props.StatisticSquares.map((square, i) => {
                if (i < 2) {
                  return (
                    <div className="square" key={i}>
                      <div
                        className="top-bar"
                        style={{ background: square.linearGradient }}
                      ></div>
                      <div
                        className={`side-bar${
                          i % 2 === 1 ? ' hide-small' : ''
                        }`}
                      ></div>
                      <div className="square-content">
                        <img
                          src={square.icon && square.icon.url}
                          alt={square.icon && square.icon.alternativeText}
                          width={square?.icon?.width}
                          height={square?.icon?.height}
                        />
                        <p
                          className="big"
                          style={{ color: square.coloredTextColor }}
                        >
                          {square.coloredText}
                        </p>
                        <p>{square.subtext}</p>
                      </div>
                    </div>
                  );
                }
                return null;
              })}
            </div>
            <div className="square-group">
              {this.props.StatisticSquares.map((square, i) => {
                if (i >= 2) {
                  return (
                    <div className="square" key={i}>
                      <div
                        className="top-bar"
                        style={{ background: square.linearGradient }}
                      ></div>
                      <div
                        className={`side-bar${
                          i % 2 === 1 ? ' hide-small' : ''
                        }`}
                      ></div>
                      <div className="square-content">
                        <img
                          src={square.icon && square.icon.url}
                          alt={square.icon && square.icon.alternativeText}
                          width={square?.icon?.width}
                          height={square?.icon?.height}
                        />
                        <p
                          className="big"
                          style={{ color: square.coloredTextColor }}
                        >
                          {square.coloredText}
                        </p>
                        <p>{square.subtext}</p>
                      </div>
                    </div>
                  );
                }
                return null;
              })}
            </div>
          </div>

          <div className="glide-square">
            <div className="glide__track" data-glide-el="track">
              <ul className="glide__slides">
                {this.props.StatisticSquares.map((square, i) => (
                  <li className="glide__slide" key={i}>
                    <div className="square">
                      <div
                        className="top-bar"
                        style={{ background: square.linearGradient }}
                      ></div>
                      <div className="square-content">
                        <img
                          src={square.icon && square.icon.url}
                          alt={square.icon && square.icon.alternativeText}
                          width={square?.icon?.width}
                          height={square?.icon?.height}
                        />
                        <p
                          className="big"
                          style={{ color: square.coloredTextColor }}
                        >
                          {square.coloredText}
                        </p>
                        <p>{square.subtext}</p>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>

            <div className="glide__bullets" data-glide-el="controls[nav]">
              <button
                className="glide__bullet"
                data-glide-dir="=0"
                aria-label="slide bullet"
              ></button>
              <button
                className="glide__bullet"
                data-glide-dir="=1"
                aria-label="slide bullet"
              ></button>
              <button
                className="glide__bullet"
                data-glide-dir="=2"
                aria-label="slide bullet"
              ></button>
              <button
                className="glide__bullet"
                data-glide-dir="=3"
                aria-label="slide bullet"
              ></button>
            </div>
          </div>
        </section>
      );
    }
    console.error('This component require 4 squares');
    return null;
  }
}
export default StatisticList;
