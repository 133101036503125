import React, { Component } from 'react';
import { Link } from 'gatsby';
import './scss/Pricing.scss';

class Pricing extends Component {
  constructor(props) {
    super(props);
    this.render = this.render.bind(this);
  }

  PricingCards({ users, price, gradient, bannerText, contact, link }) {
    return (
      <a href={link} className="pricing-card">
        <div
          className="pricing-card-banner"
          style={gradient && { background: gradient }}
        >
          <p>{bannerText}</p>
        </div>
        <p className="pricing-card-users">USERS {users}</p>
        {contact ? (
          <p className="pricing-card-contact">Contact Us ›</p>
        ) : (
          <div className="pricing-card-price-line">
            <p className="pricing-card-price">{price}</p>
            <p className="pricing-card-per">
              per practice
              <br />
              user per month
            </p>
          </div>
        )}
      </a>
    );
  }

  render() {
    return (
      <section id="pricing" className="Pricing">
        <h3>Pricing</h3>
        <p>Cancel at any time</p>

        <div className="pricing-cards">
          <this.PricingCards
            users={'1-10'}
            price={'$49'}
            bannerText={'EXTENDED FREE TRIAL'}
            link="/checkout"
          />
          <this.PricingCards
            users={'11-20'}
            price={'$44'}
            bannerText={'EXTENDED FREE TRIAL'}
            link="/checkout"
          />
          <this.PricingCards
            users={'21-75'}
            price={'$39'}
            bannerText={'EXTENDED FREE TRIAL'}
            link="/checkout"
          />
          <this.PricingCards
            users={'76+'}
            gradient={'linear-gradient(#9485ed 0%, #5d51a2 100%)'}
            bannerText={'ENTERPRISE SOLUTION'}
            contact={true}
            link="https://business.amwell.com/contact-us/"
          />
        </div>
      </section>
    );
  }
}
export default Pricing;
