import React, { Component } from 'react';
import { Link } from 'gatsby';
import Glide from '@glidejs/glide';
import '@glidejs/glide/src/assets/sass/glide.core.scss';
import Play from '../icons/Play';
import './scss/ImageCarouselWithText.scss';

class ImageCarouselWithText extends Component {
  constructor(props) {
    super(props);
    this.render = this.render.bind(this);

    const sliderConfiguration = {
      slidesToShow: 1,
      slidesToScroll: 1,
      gap: 20,
      draggable: true,
      autoplay: 4500,
      dots: '.glide__bullets',
      arrows: {
        prev: '.glide__arrow--left',
        next: '.glide__arrow--right',
      },
      type: 'carousel',
    };

    this.slider = new Glide('.glide', sliderConfiguration);

    // Update glider dots at the beginning of slide change instead of the end
    this.slider.on('run.before', (s) => {
      const slideCount = this.slider._c.Html.slides.length;
      const sliderContainer = document.querySelector(
        `${this.slider.selector} .glide__bullets`
      );
      let { index } = this.slider;
      sliderContainer.children[index].classList.remove('glide__bullet--active');
      if (s.direction === '>') {
        index = (++index + slideCount) % slideCount; // Handles wrapping
      } else if (s.direction === '<') {
        index = (--index + slideCount) % slideCount; // Handles wrapping
      } else if (s.direction === '=') {
        index = parseInt(s.steps, 10);
      } else {
        console.log(`Unsupported direction "${s.direction}"`);
      }
      sliderContainer?.children[index].classList.add('glide__bullet--active');
    });
  }

  componentDidMount() {
    this.slider.mount();
  }

  componentWillUnmount() {
    this.slider.destroy();
  }

  render() {
    return (
      <section id="HowItWorks" className="ImageCarouselWithText">
        <h3>{this.props.Title}</h3>

        <div className="grid-container">
          <div className="glide-container">
            <div>
              <div className="glide">
                <div className="glide__track" data-glide-el="track">
                  <ul className="glide__slides">
                    {this.props.slideImages &&
                      this.props.slideImages.map((image, i) => (
                        <li className="glide__slide" key={i}>
                          <img
                            src={image.formats.medium.url}
                            alt={image.alternativeText}
                            width={image.formats.medium.width}
                            height={image.formats.medium.height}
                          />
                          <p>{image.caption}</p>
                        </li>
                      ))}
                  </ul>
                </div>

                <div className="glide__arrows" data-glide-el="controls">
                  <button
                    className="glide__arrow glide__arrow--left"
                    data-glide-dir="<"
                    aria-label="Previous Slide"
                  >
                    <svg
                      width="15"
                      height="24"
                      viewBox="0 0 15 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.6532 23.3782L0.628147 13.4818C0.232601 13.0849 0.000729711 12.5557 0.000729704 12C0.000729697 11.4443 0.218962 10.9151 0.628147 10.5182L10.6532 0.62183C11.0624 0.224917 11.5943 3.23673e-08 12.1672 2.5536e-08C12.74 1.87047e-08 13.272 0.224917 13.6812 0.62183C14.0767 1.01874 14.3086 1.54796 14.3086 2.10364C14.3086 2.65932 14.0904 3.18853 13.6812 3.58545L5.15647 12L13.6812 20.4146C14.0767 20.8115 14.3086 21.3407 14.3086 21.8964C14.3086 22.452 14.0904 22.9813 13.6812 23.3782C13.272 23.7751 12.74 24 12.1672 24C11.5807 24 11.0487 23.7751 10.6532 23.3782Z"
                        fill="#007AC9"
                      />
                    </svg>
                  </button>
                  <button
                    className="glide__arrow glide__arrow--right"
                    data-glide-dir=">"
                    aria-label="Next Slide"
                  >
                    <svg
                      width="15"
                      height="24"
                      viewBox="0 0 15 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.65539 0.621828L13.6804 10.5182C14.076 10.9151 14.3079 11.4443 14.3079 12C14.3079 12.5557 14.0896 13.0849 13.6804 13.4818L3.65539 23.3782C3.24621 23.7751 2.71427 24 2.14141 24C1.56855 24 1.0366 23.7751 0.627418 23.3782C0.231872 22.9813 -6.76635e-08 22.452 -9.1953e-08 21.8964C-1.16242e-07 21.3407 0.218232 20.8115 0.627418 20.4146L9.15212 12L0.627417 3.58544C0.231871 3.18853 -9.32831e-07 2.65932 -9.5712e-07 2.10364C-9.8141e-07 1.54796 0.218231 1.01874 0.627417 0.621828C1.0366 0.224915 1.56854 -6.85633e-08 2.1414 -9.36038e-08C2.7279 -1.19241e-07 3.25985 0.224914 3.65539 0.621828Z"
                        fill="#007AC9"
                      />
                    </svg>
                  </button>
                </div>

                <div className="glide__bullets" data-glide-el="controls[nav]">
                  {this.props.slideImages &&
                    this.props.slideImages.map((_, i) => (
                      <button
                        className="glide__bullet"
                        key={i}
                        data-glide-dir={`=${i}`}
                        aria-label="slide bullet"
                      ></button>
                    ))}
                </div>
              </div>
            </div>
          </div>

          <div className="text-container">
            <h4>{this.props.subtitle1}</h4>
            <h5>{this.props.subtitle2}</h5>
            <p>{this.props.description}</p>
          </div>

          {(() => {
            if (this.props.buttonUrl && this.props.buttonText) {
              return (
                <Link to={this.props.buttonUrl} className="primary-button">
                  <Play />
                  {this.props.buttonText}
                </Link>
              );
            }
            return (
              <div
                className="primary-button"
                style={{ visibility: 'hidden' }}
              />
            );
          })()}
        </div>
      </section>
    );
  }
}
export default ImageCarouselWithText;
