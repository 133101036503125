import React, { Component } from 'react';
import './scss/Features.scss';
import { CheckIcon } from '../icons';

class Features extends Component {
  constructor(props) {
    super(props);
    this.render = this.render.bind(this);
  }

  render() {
    return (
      <section className="Features">
        <h3>Features</h3>

        {this.props.FeatureList && (
          <ul>
            {this.props.FeatureList.map((item, i) => (
              <li key={i}>
                <div>
                  <CheckIcon />
                </div>{' '}
                {item.feature}
              </li>
            ))}
          </ul>
        )}

        {!this.props.FeatureList && <p>No features</p>}
      </section>
    );
  }
}
export default Features;
