import React, { Component } from 'react';
import './scss/FullWidthBanner.scss';

class FullWidthBanner extends Component {
  constructor(props) {
    super(props);
    this.render = this.render.bind(this);
  }

  render() {
    return (
      <section className="FullWidthBanner">
        <style
          dangerouslySetInnerHTML={{
            __html: `
          .FullWidthBanner {
            background-image: url(${this.props.mobileImage});
          }

          @media (min-width: 536px) {
            .FullWidthBanner {
              background-image: url(${this.props.tabletImage});
            }
          }

          @media (min-width: 1000px) {
            .FullWidthBanner {
              background-image: url(${this.props.desktopImage});
            }
          }
        `,
          }}
        ></style>
      </section>
    );
  }
}

export default FullWidthBanner;
