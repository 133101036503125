import React, { Component } from 'react';
import './scss/IconCardsWithText.scss';
import Glide from '@glidejs/glide';
import '@glidejs/glide/src/assets/sass/glide.core.scss';

class IconCardsWithText extends Component {
  constructor(props) {
    super(props);
    this.render = this.render.bind(this);

    const sliderConfiguration = {
      gap: 0,
      dots: '.glide__bullets',
      type: 'slider',
      focusAt: 'center',
      peek: { before: 25, after: 25 },
    };

    this.slider = new Glide('.glide-purpose', sliderConfiguration);

    // Update glider dots at the beginning of slide change instead of the end
    this.slider.on('run.before', (s) => {
      const slideCount = this.slider._c.Html.slides.length;
      const sliderContainer = document.querySelector(
        `${this.slider.selector} .glide__bullets`
      );
      let { index } = this.slider;
      sliderContainer.children[index].classList.remove('glide__bullet--active');
      if (s.direction === '>') {
        index = (++index + slideCount) % slideCount; // Handles wrapping
      } else if (s.direction === '<') {
        index = (--index + slideCount) % slideCount; // Handles wrapping
      } else if (s.direction === '=') {
        index = parseInt(s.steps, 10);
      } else {
        console.log(`Unsupported direction "${s.direction}"`);
      }
      sliderContainer.children[index].classList.add('glide__bullet--active');
    });
  }

  componentDidMount() {
    if (this.props.Cards.length === 3) {
      const imgs = document.querySelectorAll('.glide-purpose img');

      let imagesLoaded = 0;
      const loaded = () => {
        imagesLoaded++;
        if (imagesLoaded === imgs.length) {
          this.slider.mount();
        }
      };

      imgs.forEach((img) => {
        if (img.complete) {
          loaded();
        } else {
          // eslint-disable-next-line no-param-reassign
          img.onload = loaded;
        }
      });
    }
  }

  componentWillUnmount() {
    this.slider.destroy();
  }

  render() {
    if (this.props.Cards.length === 3) {
      return (
        <section id="Benefits" className="IconCardsWithText">
          <h3>{this.props.Title}</h3>

          <div className="card-grid">
            {this.props.Cards &&
              this.props.Cards.map((card, i) => {
                if (card.title || card.icon || card.description) {
                  return (
                    <React.Fragment key={i}>
                      <div className={`grid-card c${i}`}></div>
                      <h4 className={`card-title c${i}`}>{card.title}</h4>
                      <img
                        className={`card-icon c${i}`}
                        src={card.icon && card.icon.url}
                        width={card?.icon?.width}
                        height={card?.icon?.height}
                        alt={card.icon && card.icon.alternativeText}
                      />
                      <div
                        className={`card-text c${i}`}
                        dangerouslySetInnerHTML={{ __html: card.description }}
                      />
                    </React.Fragment>
                  );
                }
                return null;
              })}
          </div>

          <div className="glide-purpose">
            <div className="glide__track" data-glide-el="track">
              <ul className="glide__slides">
                {this.props.Cards &&
                  this.props.Cards.map((card, i) => {
                    if (card.title || card.icon || card.description) {
                      return (
                        <li className="glide__slide" key={i}>
                          <div className="card">
                            <h4>{card.title}</h4>
                            <img
                              src={card.icon && card.icon.url}
                              width={card?.icon?.width}
                              height={card?.icon?.height}
                              alt={card.icon && card.icon.alternativeText}
                            />
                            <div
                              dangerouslySetInnerHTML={{
                                __html: card.description,
                              }}
                            />
                          </div>
                        </li>
                      );
                    }
                    return null;
                  })}
              </ul>
            </div>

            <div className="glide__bullets" data-glide-el="controls[nav]">
              {this.props.Cards &&
                this.props.Cards.map((_, i) => (
                  <button
                    className="glide__bullet"
                    data-glide-dir={`=${i}`}
                    aria-label="slide bullet"
                    key={i}
                  ></button>
                ))}
            </div>
          </div>
        </section>
      );
    }
    console.error('This component requires 3 cards');
    return null;
  }
}
export default IconCardsWithText;
