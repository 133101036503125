import React, { Component } from 'react';
import Glide from '@glidejs/glide';
import Quote from '../icons/Quote';
import './scss/Testimonials.scss';

class Testimonials extends Component {
  constructor(props) {
    super(props);
    this.render = this.render.bind(this);

    const sliderConfiguration = {
      gap: 15,
      dots: '.glide__bullets',
      type: 'slider',
      focusAt: 'center',
      peek: { before: 50, after: 50 },
    };

    this.slider = new Glide('.glide-testimonials', sliderConfiguration);

    // Update glider dots at the beginning of slide change instead of the end
    this.slider.on('run.before', (s) => {
      const slideCount = this.slider._c.Html.slides.length;
      const sliderContainer = document.querySelector(
        `${this.slider.selector} .glide__bullets`
      );
      let { index } = this.slider;
      sliderContainer.children[index].classList.remove('glide__bullet--active');
      if (s.direction === '>') {
        index = (++index + slideCount) % slideCount; // Handles wrapping
      } else if (s.direction === '<') {
        index = (--index + slideCount) % slideCount; // Handles wrapping
      } else if (s.direction === '=') {
        index = parseInt(s.steps, 10);
      } else {
        console.log(`Unsupported direction "${s.direction}"`);
      }
      sliderContainer.children[index].classList.add('glide__bullet--active');
    });
  }

  componentDidMount() {
    const imgs = document.querySelectorAll('.glide-testimonials img');

    let imagesLoaded = 0;
    const loaded = () => {
      imagesLoaded++;
      if (imagesLoaded === imgs.length) {
        this.slider.mount();
      }
    };

    imgs.forEach((img) => {
      if (img.complete) {
        loaded();
      } else {
        // eslint-disable-next-line no-param-reassign
        img.onload = loaded;
      }
    });
  }

  componentWillUnmount() {
    this.slider.destroy();
  }

  render() {
    return (
      <section className="Testimonials">
        <h3>{this.props.Title}</h3>

        <div className="card-container">
          {this.props.TestimonialCard &&
            this.props.TestimonialCard.map((card, i) => (
              <div className="card" key={i}>
                <div className="card-quote">
                  <Quote />
                  <p>{card.quote}</p>
                </div>
                <div className="card-footer">
                  <img
                    src={card.picture && card.picture.url}
                    alt={card.picture && card.picture.alternativeText}
                    width={card?.picture?.width}
                    height={card?.picture?.height}
                  />
                  <p>
                    <strong>{card.name}</strong>
                    <br />
                    {card.field}
                  </p>
                </div>
              </div>
            ))}
        </div>

        <div className="glide-testimonials">
          <div className="glide__track" data-glide-el="track">
            <ul className="glide__slides">
              {this.props.TestimonialCard &&
                this.props.TestimonialCard.map((card, i) => (
                  <li className="glide__slide" key={i}>
                    <div className="card">
                      <div className="card-quote">
                        <Quote />
                        <p>{card.quote}</p>
                      </div>
                      <div className="card-footer">
                        <img
                          src={card.picture && card.picture.url}
                          alt={card.picture && card.picture.alternativeText}
                          width={card?.picture?.width}
                          height={card?.picture?.height}
                        />
                        <p>
                          <strong>{card.name}</strong>
                          <br />
                          {card.field}
                        </p>
                      </div>
                    </div>
                  </li>
                ))}
            </ul>
          </div>

          <div className="glide__bullets" data-glide-el="controls[nav]">
            {this.props.TestimonialCard &&
              this.props.TestimonialCard.map((_, i) => (
                <button
                  className="glide__bullet"
                  key={i}
                  data-glide-dir={`=${i}`}
                  aria-label="slide bullet"
                ></button>
              ))}
          </div>
        </div>
      </section>
    );
  }
}
export default Testimonials;
